<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Step Process Assign</h4>
            </template>
            <template v-slot:body>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)">
                  <b-row>
                    <b-col lg="12" xl="12">
                      <table class="table table-sm table-bordered">
                        <tr>
                          <th width="50%" colspan="2" class="text-center">{{ $t('globalTrans.from') }} ({{ $t('dynamic_form.form') }}) </th>
                          <th width="50%" colspan="2" class="text-center">{{ $t('globalTrans.to') }} ({{ $t('dynamic_form.form') }}) </th>
                        </tr>
                        <tr>
                          <td width="50%" colspan="2">
                            <ValidationProvider name="Organization" vid="org_id_from" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                  label-cols-sm="4"
                                  :label-for="`org_id_from`"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.org_id_from"
                                  :options="organizationList"
                                  id="org_id_from"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('component_settings.select_organization')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </td>
                          <td width="50%" colspan="2">
                            <ValidationProvider name="Organization" vid="org_id_to" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                  label-cols-sm="4"
                                  :label-for="`org_id_from`"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.org_id_to"
                                  :options="organizationList"
                                  id="org_id_to"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('component_settings.select_organization')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <td width="25%">
                            <ValidationProvider name="Service Name" vid="service_id_from" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                  label-cols-sm="12"
                                  :label-for="`service_id_from`"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('dropdown_settings.service') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.service_id_from"
                                  :options="serviceListFrom"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('component_settings.select_service')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </td>
                          <td width="25%">
                            <ValidationProvider name="Form" vid="form_id_from" rules="required">
                              <b-form-group
                                class="row"
                                  label-cols-sm="12"
                                  :label-for="`form_id_from`"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('dynamic_form.form') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.form_id_from"
                                  :options="formListFrom"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option value="" >{{$t('component_settings.select_form')}}</b-form-select-option>
                                    <b-form-select-option value=0 >Profile form</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </td>
                          <td width="25%">
                            <ValidationProvider name="Service Name" vid="service_id_to" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                  label-cols-sm="12"
                                  :label-for="`service_id_to`"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('dropdown_settings.service') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.service_id_to"
                                  :options="serviceListTo"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('component_settings.select_service')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </td>
                          <td width="25%">
                            <ValidationProvider name="Form" vid="form_id_to" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                  label-cols-sm="12"
                                  :label-for="`form_id_to`"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('dynamic_form.form') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.form_id_to"
                                  :options="formListTo"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr>
                          <td width="50%" colspan="2">
                            <b-row>
                              <b-col lg="10" xl="10">
                                <ValidationProvider name="Field" :vid="`field_id_from`" rules="">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label-for="`field_id_from`"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.select_field') }}
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="formData.field_id_from"
                                      :options="inputListFrom"
                                      :id="`field_id_from`"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                          </td>
                          <td colspan="2">
                            <b-row>
                              <b-col lg="10" xl="10">
                                <ValidationProvider name="Field" :vid="`field_id_to`" rules="">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label-for="`field_id_to`"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.select_field') }}
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="formData.field_id_to"
                                      :options="inputListTo"
                                      :id="`field_id_to`"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                        <b-form-select-option value="">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="2" xl="2">
                                <b-button @click="addNew()" type="button" variant="primary" class="mr-2">{{ $t('globalTrans.add') }}</b-button>
                              </b-col>
                            </b-row>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                    <b-col lg="12" xl="12">
                      <table class="table table-sm table-bordered">
                        <tr>
                          <th width="50%" colspan="2" class="text-center">{{ $t('globalTrans.from') }} {{ $t('component_settings.field') }}</th>
                          <th width="50%" colspan="2" class="text-center">{{ $t('globalTrans.to') }}  {{ $t('component_settings.field') }}</th>
                        </tr>
                        <tr v-for="(item, index) in formData.details" :key="index">
                          <td width="50%" colspan="2">
                            <b-row>
                              <b-col lg="10" xl="10">
                                <ValidationProvider name="Field" :vid="`field_id_from`+index" rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label-for="`field_id_from`+index"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.select_field') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="item.field_id_from"
                                      :options="inputListFrom"
                                      disabled
                                      :id="`field_id_from`+index"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                          </td>
                          <td colspan="2">
                            <b-row>
                              <b-col lg="10" xl="10">
                                <ValidationProvider name="Field" :vid="`field_id_to`+index" rules="required">
                                  <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    :label-for="`field_id_to`+index"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('globalTrans.select_field') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                      plain
                                      v-model="item.field_id_to"
                                      :options="inputListTo"
                                      disabled
                                      :id="`field_id_to`+index"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                        <b-form-select-option value="">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col lg="2" xl="2">
                                <b-button @click="remove(index)" type="button" variant="danger" class="mr-2">x</b-button>
                              </b-col>
                            </b-row>
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { fieldAssignStore, fieldAssignUpdate, dynamicFormEditData } from '../../api/routes'
import { snakeToWords } from '@/Utils/fliter'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        org_id_from: 0,
        org_id_to: 0,
        service_id_from: 0,
        service_id_to: 0,
        form_id_from: '',
        form_id_to: 0,
        field_id_f: 0,
        field_id_to: 0,
        details: []
      },
      formListTo: [],
      formListFrom: [],
      inputListFrom: [],
      inputListTo: [],
      serviceListFrom: [],
      serviceListTo: [],
      loading: false
    }
  },
  created () {
    if (this.$route.query.id) {
      this.formData = this.getEditData()
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    allFormField: function () {
      return [
        {
          value: 'name',
          field_type: 'InputField',
          text: 'Name'
        },
        {
          value: 'name_bn',
          field_type: 'InputField',
          text: 'Name Bn'
        },
        {
          value: 'email',
          field_type: 'InputField',
          text: 'Email'
        },
        {
          value: 'mobile_no',
          field_type: 'InputField',
          text: 'Mobile No'
        },
        {
          value: 'nid',
          field_type: 'InputField',
          text: 'Nid'
        },
        {
          value: 'photo',
          field_type: 'Attachment',
          text: 'Photo'
        },
        {
          value: 'dob',
          field_type: 'Date',
          text: 'Dob'
        },
        {
          value: 'gender',
          field_type: 'Dropdown',
          text: 'Gender'
        },
        {
          value: 'religion',
          field_type: 'InputField',
          text: 'Religion'
        },
        {
          value: 'father_name',
          field_type: 'InputField',
          text: 'Father Name'
        },
        {
          value: 'father_name_bn',
          field_type: 'InputField',
          text: 'Father Name Bn'
        },
        {
          value: 'mother_name',
          field_type: 'InputField',
          text: 'Mother Name'
        },
        {
          value: 'mother_name_bn',
          field_type: 'InputField',
          text: 'Mother Name Bn'
        },
        {
          value: 'spouse_name',
          field_type: 'InputField',
          text: 'Spouse Name'
        },
        {
          value: 'spouse_name_bn',
          field_type: 'InputField',
          text: 'Spouse Name Bn'
        },
        {
          value: 'occupation',
          field_type: 'InputField',
          text: 'Occupation'
        },
        {
          value: 'occupation_bn',
          field_type: 'InputField',
          text: 'Occupation Bn'
        },
        {
          value: 'division_id',
          field_type: 'Dropdown',
          text: 'Division Id'
        },
        {
          value: 'district_id',
          field_type: 'Dropdown',
          text: 'District Id'
        },
        {
          value: 'upazilla_id',
          field_type: 'Dropdown',
          text: 'Upazilla Id'
        },
        {
          value: 'present_address',
          field_type: 'Paragraph',
          text: 'Present Address'
        },
        {
          value: 'permanent_address',
          field_type: 'Paragraph',
          text: 'Permanent Address'
        }
      ]
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    dynamicButtonList: function () {
      return this.$store.state.licenseRegistration.commonObj.dynamicButtonList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.org_id_from': function (newVal) {
      this.serviceListFrom = this.getServiceList(newVal)
    },
    'formData.org_id_to': function (newVal) {
      this.serviceListTo = this.getServiceList(newVal)
    },
    'formData.service_id_from': function (n, o) {
        if (n !== o) {
          this.formListFrom = this.getStepWiseForm(n, 2)
        }
    },
    'formData.service_id_to': function (n, o) {
       if (n !== o) {
        this.formListTo = this.getStepWiseForm(n, 2)
       }
    },
    'formData.form_id_from': function (n, o) {
       if (n !== o) {
        if (n > 0) {
          this.inputListFrom = this.getInputList(n)
        } else {
          this.inputListFrom = this.allFormField
        }
       }
    },
    'formData.form_id_to': function (n, o) {
       if (n !== o) {
        this.inputListTo = this.getInputList(n)
       }
    }
  },
  methods: {
    addNew () {
      const tmp = {
        field_id_from: this.formData.field_id_from,
        field_id_to: this.formData.field_id_to
      }
      this.formData.details.push(tmp)
    },
    remove (index) {
      this.formData.details.splice(index, 1)
    },
    removeBtn (index) {
      this.formData.custom_actions.splice(index, 1)
    },
    getStepWiseForm (stepOrServiceId, type) {
        const tmpFormList = this.$store.state.licenseRegistration.commonObj.serviceStepForm.filter(item => (item.service_id === parseInt(stepOrServiceId)))
        const stepList = tmpFormList.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.form_title, value: item.form_id }
            } else {
              return { text: item.form_title, value: item.form_id }
            }
        })
        return stepList
    },
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.$route.query.id))
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.$route.query.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${fieldAssignUpdate}/${this.$route.query.id}`, this.formData)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, fieldAssignStore, this.formData)
      }
      this.loading = false
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.$route.query.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$router.go(-1)
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getInputList (dynamicFormId) {
      this.loading = true
      const finalList = []
      RestApi.getData(licenseRegistrationServiceBaseUrl, dynamicFormEditData + '/' + dynamicFormId).then(response => {
        if (response.success) {
          response.data.tabs.forEach(item => {
            const tmpList = {
                label: snakeToWords(item.tab_name),
                options: []
              }
            item.layouts.forEach(i => {
              const input = JSON.parse(i.data)
              if (input.field_type === 'text' || input.field_type === 'number' || input.field_type === 'options' || input.field_type === 'dropdown' || input.field_type === 'text_area' || input.field_type === 'text' || input.field_type === 'email' || input.field_type === 'date') {
                  tmpList.options.push({
                    text: input.label, value: input.field_name
                  })
                }
            })
            finalList.push(tmpList)
          })
          finalList.push({ text: 'License Number', value: 'generate_id' })
          finalList.push({ text: 'Issue Date', value: 'issue_date' })
          this.loading = false
        }
      })
      return finalList
    },
    getServiceList (orgId) {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
    }
  }
}
</script>
<style scoped>
  .form-style-2-heading{
    color: #3f414d;
    font-style: italic;
    border-bottom: 1px solid #3f414d;
    margin-bottom: 20px;
    font-size: 15px;
    padding-bottom: 3px;
  }
</style>
